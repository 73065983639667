//GLOBAL COLOR OVERIDES

.main {                                                 // within Main window
    background: #fefefe!important;

     // Set Primary Color to Green (from Blue) 

    .sidebar-toggle:hover .hamburger,                   // sidebar toggle
    .sidebar-toggle:hover .hamburger:after, 
    .sidebar-toggle:hover .hamburger:before{
        background: $color__primary!important;
    }

    a{                                                  // all links
        color: $color__primary!important;

        &:hover {                                       // all links hover
            color: $color__primary!important;        
        }
    }

    .MuiBadge-badge {
        background-color: $color__primary!important;
    }

    .MuiPaper-root.MuiAlert-root {                      // error message
        margin-bottom:1rem;
    }

    .MuiTypography-root.MuiAlertTitle-root {            // success message
        margin-bottom:0px;
    }

    .MuiAlert-message ul,
    .MuiAlert-message ol {
        margin-bottom:2px;
    }



     // Table Lists

     .table_list,
     .views_view {
        
        .card {
            margin-bottom:0;
            background:none;
            box-shadow:none;
        }

        .MuiDataGrid-root {                                 // MUI table remove border
            border:none!important;
        }
     }

     .MuiTabPanel-root {
        padding:0!important;
     }

     .MuiDataGrid-toolbarContainer {                     // MUI table toolbar padding
        padding: 0.5rem 0.8rem;

        button.MuiButton-root {                         // MUI table text formatting
            letter-spacing: 0;
            text-transform: none;
            padding: 0.25rem 0.6rem;
            color: $color__primary;

            &:hover {
                background-color: rgba($color__primary, 0.04);
            }
        }

    }
     .table_list {

        .card-body {
            padding:0!important;

            .MuiDataGrid-root {                                 // MUI table remove border
                border:none!important;
            }

            .MuiDataGrid-columnHeader{                          // MUI table remove focus outline on column header
                &:focus-within,
                &:focus {
                    outline: none!important;
                }
        
            }
        
            .Mui-checked,                                       // MUI checkbox color
            .MuiCheckbox-indeterminate {
                color: $color__primary!important;      
            }
        
            .MuiCheckbox-root:hover {                           // MUI checkbox hover color
                background-color: rgba($color__primary, 0.06);
            }
        
        
            .MuiDataGrid-row {                                  // MUI rows and cells
        
                &.Mui-selected{                                  // MUI selected row background color
                    background-color: rgba($color__primary, 0.08)!important;
            
                    &:hover {
                        background-color: rgba($color__primary, 0.12)!important;
                    }
                }
        
                .MuiDataGrid-cell{                              // MUI table cell focus
                    &:focus-within{
                        outline: solid rgba($color__primary, 0.5) 1px;
                    }
                    &:focus {
                        outline: solid rgba($color__primary, 1) 1px;
                    }
            
                }
        
            }

        }

        .MuiTablePagination-selectLabel,
        .MuiTablePagination-displayedRows {
            margin-bottom:0;
        }

    }

    .views_view {

        .MuiOutlinedInput-root,                                 // Input Max Width 767px
        .MuiInput-root {
            max-width:767px;

        }

        .MuiOutlinedInput-root:hover {        // Input Field hover
             .MuiOutlinedInput-notchedOutline {
                border-color: rgba($color__primary, .87);
            }
            &.Mui-disabled .MuiOutlinedInput-notchedOutline { // Disabled Input Field hover
                border-color: rgba(0, 0, 0, 0.26)!important;
            }
        }

        .MuiInputLabel-root {                                   // Input Feild Placeholder
            color: rgba(0, 0, 0, 0.4);
            line-height: 1rem;
        }

        input.MuiInputBase-input,
        .MuiSelect-select{                               // Input Fields Padding
            padding: 12px 14px!important
        }
        
        .MuiInputBase-root{                                     // Dropdown Field Parent Padding
            padding:0;
        }

        .MuiInputBase-adornedStart {                            // Phone Field Styles
            .MuiPhoneNumber-flagButton {
                padding-left:10px!important;
            }
            .MuiInputBase-input {
                padding-left:0!important;
            }
        }


        
        .components__order-editor {
            h3 {
                letter-spacing: .2rem;
                color: $color__primary;
            }
            .components__order-transfers-editor {
                ul {
                    margin-bottom:0;
                }
            }
        }
        

    }

    .MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
        background-color: rgba($color__primary, 0.12)!important;
    }

    // Breadcrumbs

    nav[aria-label="breadcrumb"]{
        font-size: .8rem;

        svg {
            padding-bottom:2px;
        }
    }
   
}

// Left hand sidebar 

.sidebar, .sidebar-content {
    background: #273626!important;

    .siderbar-link,
    a.sidebar-link {
        background: #273626!important;

        &:hover {
            background: #233022!important;
        }
    }

    .sidebar-item.active > .sidebar-link {              // Left hand sidebard menu item selected color
        background: linear-gradient(90deg, rgba($color__primary, 0.1), rgba($color__primary, 0.0875) 50%, transparent)!important;
        border-left-color: $color__primary!important;
    }

}

.MuiButtonBase-root.MuiButton-root{                     //Buttons
    padding:10.25px 25px;
    letter-spacing: .1rem;
    margin: 0 10px 10px 0;

    &.MuiButton-contained{
        background-color: $color__primary!important;
        border:1px solid rgba($color__primary, 0.5)!important;

        &:hover {
            background-color: $color__primary-dark!important;
        }
    }
    &.MuiButton-outlined{
        color: $color__primary!important;
        border:1px solid rgba($color__primary, 0.5)!important;

        &:hover {
            background-color: rgba($color__primary, 0.04);
        }
    }
    &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26)!important;
        box-shadow: none!important;
        background-color: rgba(0, 0, 0, 0.12)!important;
        border:1px solid rgba(0, 0, 0, 0.12)!important;
    }
}

.MuiButtonBase-root.MuiTab-root{   
    color: $color__primary!important;
}

.MuiTabs-indicator {
    background-color: $color__primary!important;

}

 // iframes & tooltips
    
.MuiSwitch-switchBase.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked {  // Columns Toggle On Color
    color: $color__primary!important;

    +.MuiSwitch-track {
        background: $color__primary!important;
    }
}


button.MuiButtonBase-root.MuiButton-text {   // Tooltip buttons
    color: $color__primary!important;

    &:hover {
        background-color: rgba($color__primary, 0.04);
    }

}

.MuiFormLabel-root.MuiInputLabel-root.Mui-focused[data-shrink="true"] {     //Form Labels on Focus
    color:$color__primary!important;
}

.MuiFormLabel-root.Mui-focused {
    color:$color__primary!important;
}

.MuiOutlinedInput-root.Mui-focused fieldset{            //Form Input Outline on Focus
    border-color:$color__primary!important;
}

.MuiInput-root.MuiInputBase-root.Mui-focused:after { //Form Input Underline on Focus
    border-bottom: 2px solid $color__primary!important;
}

.MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
    background-color: rgba($color__primary, 0.08)!important;
}

.MuiCircularProgress-circle {
    color:$color__primary!important;
}

.MuiRadio-root.Mui-checked {
    color:$color__primary!important;
}
.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight {
    background-color: rgba($color__primary, 0.6);
}
.MuiDateRangePickerDay-day.Mui-selected {
    background-color: $color__primary!important;
}
// Hide mapbox controls
.mapboxgl-ctrl {
    display: none !important;
}


.MuiDialogTitle-root {
    padding: 0 0 1rem;
}

.MuiPaper-root.MuiDialog-paper {
    padding:2rem;
}


@media (min-width:700px) {
    .activity-date-picker {
        position: absolute;
        right: 0;
        top: 35px;
    }
}